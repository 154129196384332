import React from "react";
import { Content } from "./Content";

export const Subtitle = ({ children }) => (
  <Content
    as="h2"
    style={{
      fontSize: "var(--fontsize-heading-m)",
      lineHeight: "var(--lineheight-m)",
    }}
  >
    {children}
  </Content>
);
