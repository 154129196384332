import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import { Subtitle } from "../../components/Subtitle";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const IndexPage = () => (
  <Page language="en" page="Home">
    <Row>
      <Col>
        <Title>Slipping warning service</Title>
      </Col>
    </Row>

    <Row>
      <Col md={9}>
        <Subtitle>
          Slippery road conditions can be warned of with a text message. The
          service is free for the warned people.
        </Subtitle>

        <Content>
          We have received excellent feedback on the warning service. A small
          investment yields a multiplied benefit.
        </Content>

        <Content>
          The warning message is sent by the winter maintenance duty officer or
          by YIT’s service centre PANU, while the sanding personnel are called
          out to work as required, based on 24/7 real-time monitoring of the
          situation. The warning message shall be sent on the grounds of road
          condition forecasts, if the warning person is confident from his
          experience that dangerous slipperiness shall exist. As a starting
          point, the warning is based on a real situation.
        </Content>
      </Col>

      <Col md={3}>
        <Subtitle>Sign up for the service</Subtitle>

        <Content>
          By clicking the name of the city, you will get instructions for
          signing in for the service:
        </Content>

        <Content as="ul">
          <li>
            <a href="/en/sign-up/helsinki">Helsinki</a>
          </li>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default IndexPage;
